import firebase from 'firebase/app';
import 'firebase/auth';
// import 'firebase/firestore';

//enter your web app configuration options
const firebaseConfig = {
  apiKey: "AIzaSyAmY8Lmz9SDSntPR6rnfp6R32eoWGp5B54",
  authDomain: "zola-ff4f4.firebaseapp.com",
  databaseUrl: "https://zola-ff4f4-default-rtdb.firebaseio.com/",
  projectId: "zola-ff4f4",
  storageBucket: "zola-ff4f4.appspot.com",
  messagingSenderId: "83515956456",
  appId: "1:83515956456:web:35de7175957856be787d5d",
  measurementId: "G-908J8XREX6"
};

if (!firebase.apps.length) firebase.initializeApp(firebaseConfig);

export { firebase };