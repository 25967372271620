/* Local settings */
//exports.baseAPIURL = 'http://localhost:8080/api/';
//exports.rootURL = "http://localhost:3001/";

/* Production */
exports.baseAPIURL = "https://zola-ff4f4.nw.r.appspot.com/api/";
exports.rootURL = "https://zola-ff4f4.web.app/";

exports.secretOrKey = "hGXoNRl8ukug48pCfY2p";

exports.subscriptionProducts = {
  monthly_vip_subscription: {
    price: 5,
    period: "month",
  },
  weekly_vip_subscription: {
    price: 45,
    period: "year",
  },
};
